import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAllCategories } from "../../../ecommerce/services";
import {
  GetAllCompanyPreferences,
  addProductToFavorites,
  getAllProducts,
  getAllSalesPersonByCompanyName,
  getFavoritesProducts,
  getFilteredProducts,
  getProductById,
  removeProductFromFavorites,
  sendClientContactInfo,
} from "../../../ecommerce/services/api";

import { errorToast, successToast } from "../../../services/toasts";
import { QUERY_KEYS } from "../queryKeys";
import { useFilterStore } from "../../../supplierEcommerce/store";

export const useGetAllCategories = () => {
  return useQuery(QUERY_KEYS.GET_CATEGORIES, getAllCategories, {
    staleTime: 3600000,
  });
};

// Favorite Products

export const useGetFavorites = (enabled: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_FAVORITES],
    refetchOnWindowFocus: false,
    queryFn: getFavoritesProducts,
    enabled,
  });
};

export const useSaveFavorite = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (productId: number) => addProductToFavorites(productId),
    onSuccess: () => {
      queryClient.invalidateQueries("favorites");
      successToast("Producto agregado a favoritos");
    },
  });
};

export const useDeleteFavorite = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (productId: number) => removeProductFromFavorites(productId),
    onSuccess: () => {
      queryClient.invalidateQueries("favorites");
    },
  });
};

export const useGetAllProducts = (companyName: string) => {
  const { filter, sort, pagination } = useFilterStore();
  return useQuery({
    queryKey: [
      QUERY_KEYS.GET_ALL_PRODUCTS,
      companyName,
      filter,
      sort,
      pagination,
    ],
    queryFn: () => getAllProducts(companyName, filter, sort, pagination),
    refetchOnWindowFocus: false,
  });
};

export const useGetFilters = (companyName: string) => {
  const setPrice = useFilterStore((state) => state.setPrice);
  const setMinMaxPrice = useFilterStore((state) => state.setMinMaxPrice);

  return useQuery({
    queryKey: [QUERY_KEYS.GET_FILTERS, companyName],
    queryFn: () => getFilteredProducts(companyName),
    refetchOnWindowFocus: false,
    enabled: !!companyName,
    onSuccess: (data) => {
      if (data?.price) {
        const [minPrice, maxPrice] = data?.price.map((price: string) =>
          Math.round(Number(price))
        );
        setPrice([minPrice, maxPrice]);
        setMinMaxPrice(minPrice, maxPrice);
      }
    },
  });
};

export const useGetProductById = (productId: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_PRODUCT_BY_ID, productId],
    queryFn: () => getProductById(productId),
    refetchOnWindowFocus: false,
  });
};

export const useGetAllCompanyPreferences = (companyName: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_COMPANY_DATA, companyName],
    queryFn: () => GetAllCompanyPreferences(companyName),
    refetchOnWindowFocus: false,
  });
};

export const useGetAllSalesPersonByCompanyName = (companyName: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SALES_PERSON_BY_COMPANYNAME, companyName],
    queryFn: () => getAllSalesPersonByCompanyName(companyName),
    refetchOnWindowFocus: false,
  });
};

export const useSendClientContactInfo = () => {
  return useMutation({
    mutationFn: (clientInfo: IContactInfo) => sendClientContactInfo(clientInfo),
    onSuccess: () => {
      successToast(
        "Información de contacto enviada correctamente. Nuestro equipo se pondrá en contacto contigo en breve."
      );
    },
    onError: () => {
      errorToast(
        "Hubo un problema al enviar la información de contacto. Por favor, inténtalo de nuevo."
      );
    },
  });
};
